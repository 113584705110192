import { graphql } from "gatsby"
import ProductPage from "gatsby-theme-gaviscon/src/templates/ProductPage/ProductPage"

export const query = graphql`
  query ProductQueryNZ(
    $relatedProductsLinks: [String]
    $featuredArticlesLinks: [String]
    $link: String = ""
    $lang: String
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          availableFlavorsLabel
          availableSizesLabel
          chooseFormatLabel
          buyNowLabel
          title
          preTitle
          secText
          closeModalText
          minText
        }
      }
    }
    brandSettings(lang: { eq: $lang }) {
      logo {
        ...FragmentGatsbyImage
      }
      logoAlt
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allProduct(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        id
        sku
        showBreadcrumbs
        hideProductDetails
        hideDisclaimer
        symptoms {
          properties {
            color
            text
          }
        }
        title
        productImageAlt
        link
        descriptionShort
        instructions
        relatedProductsSku
        additionalInfo {
          properties {
            mainImageAlt
            mainImage {
              ...FragmentGatsbyImage
            }
            cards {
              properties {
                text
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
            infoBlock {
              properties {
                text
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
          structure
        }
        buyNowDropdown {
          properties {
            toggleButtonTitle
            toggleButtonAriaLabel
            description
            cancelButtonTitle
            cancelButtonAriaLabel
            dropdownItems {
              properties {
                linkToShop
                ariaLabel
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
        }
        buyNow {
          name
          url
          target
          queryString
        }
        btnLogo {
          ...FragmentGatsbyImage
        }
        btnLogoAlt
        ariaBuyNow
        buyNowButtonNotice
        howItWorksButton {
          name
          url
          target
          queryString
        }
        ariaHowItWorksLabel
        buyNowCards {
          properties {
            link {
              url
              target
            }
            image {
              ...FragmentGatsbyIcon
            }
            imageAlt
            title
          }
        }
        additionalText
        currentFormat {
          properties {
            type
            label
          }
        }
        otherFormats {
          properties {
            type
            label
            link {
              url
            }
          }
        }
        flavours {
          properties {
            icon
            label
          }
        }
        sizes {
          properties {
            label
            icon
          }
        }
        ingredients
        productImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 200, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        badgeImage {
          ...FragmentGatsbyImage
        }
        badgeAlt
        infoSection {
          properties {
            description
            title
            type
            links {
              properties {
                link {
                  url
                }
                linkTo {
                  url
                }
                title
                ariaLabel
              }
            }
          }
        }
        productBanner {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentMiddleImage
              }
            }
            imageAlt
            logo {
              ...FragmentGatsbyImage
            }
            hideLogo
            hideVariant
            logoAlt
            subTitle
            mobileImage {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentMiddleImage
              }
              mobileImage {
                childImageSharp {
                  fluid(maxWidth: 380, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            mobileImageAlt
            additionalBannerImage {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentMiddleImage
              }
            }
            additionalBannerImageAlt
            bannerBackground {
              label
            }
          }
        }
        productHomeBanner {
          ...FragmentHomePageTopBanner
        }
        tags {
          name
          id
          color {
            label
          }
          isProductFamily
        }
        shopMessage {
          properties {
            title
            link {
              url
              name
              target
            }
            icons {
              properties {
                icon
                label
              }
            }
          }
        }
        enableDataSchema
        schemaName
        schemaDescription
        schemaImage {
          ...FragmentGatsbyImage
        }
        schemaBrand
        body {
          ... on TDiscoverySectionStructure {
            ...FragmentDiscoverySectionStructure
          }
          ... on TTextBlockStructure {
            structure
            properties {
              content
              maxWidth
              color
            }
          }
          ... on TStoreFinderRedirectStructure {
            structure
            properties {
              ariaButton
              button
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
              label
              queryString
              title
            }
          }
          ... on TTestimonialSectionStructure {
            structure
            properties {
              imageAvatar {
                ...FragmentGatsbyImage
              }
              imageAvatarAlt
              bgColor
              description
              imageAlignment
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
            }
          }
          ... on TInfoSectionStructure {
            structure
            properties {
              description
              image {
                ...FragmentGatsbyImage
              }
              imageAlign
              imageAlt
              video {
                structure
                properties {
                  title
                  url
                  link
                  duration
                  ariaLabel
                  videoPreviewPosterAlt
                  videoPreviewPoster {
                    ...FragmentGatsbyImage
                  }
                  playButtonAria
                }
              }
              link {
                url
                name
              }
              ariaCTA
              ctaLogo {
                ...FragmentGatsbyImage
              }
              ctaLogoAlt
              ctaButtonType
              returnsNotice
              ctaButtonNotice
              title
              sectionBackground {
                label
              }
              isShowVideoOnTop
            }
          }
          ... on TWheretobuyStructure {
            structure
            properties {
              color
              title
              listMode
              limit
              limitMobile
              cards {
                properties {
                  imageAlt
                  type
                  title
                  link {
                    url
                    target
                    queryString
                  }
                  image {
                    ...FragmentGatsbyImage
                  }
                }
              }
            }
          }
          ... on TFeaturedArticleStructure {
            structure
            properties {
              link {
                url
                name
              }
              color
              ariaButton
              imageAlign
              isManual
              manualTitle
              manualDescription
              manualImage {
                ...FragmentGatsbyProps
                gatsbyImage {
                  ...FragmentMiddleImage
                }
                mobileImage {
                  childImageSharp {
                    fluid(maxHeight: 204) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              manualImageAlt
            }
          }
          ... on TInfobannerStructure {
            structure
            properties {
              description
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
            }
          }
          ... on TRelatedProductsStructure {
            structure
            properties {
              ariaAllProducts
              limit
              title
              hideVariant
              isFamily
              linkAllProducts {
                name
                url
              }
            }
          }
          ... on TFAQblockStructure {
            structure
            properties {
              title
              color
              selectedIndex
              isExpanded
              items {
                properties {
                  answer
                  question
                }
              }
              disableSeoSchema
            }
          }
          ... on TIngredientSectionStructure {
            ...FragmentProductIngredients
          }
          ... on TProductDetailSectionStructure {
            structure
          }
          ... on TInfoBlockSectionStructure {
            structure
            properties {
              blocks: infoBlock {
                properties {
                  title
                  description
                  type
                  links {
                    properties {
                      link {
                        url
                      }
                      linkTo {
                        url
                      }
                      title
                      ariaLabel
                    }
                  }
                }
              }
              sectionBackground {
                label
              }
            }
          }
        }
      }
    }
    featuredArticles: allUmbracoArticles(
      filter: { link: { in: $featuredArticlesLinks } }
    ) {
      nodes {
        title
        link
        text
        tags {
          articleCategory
          name
        }
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentMiddleImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 204) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        alt
      }
    }
    relatedProducts: allProduct(
      filter: { link: { nin: [$link], in: $relatedProductsLinks } }
    ) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyImage
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        imageFamily {
          ...FragmentGatsbyImage
        }
        linkFamily {
          url
        }
        currentFormat {
          properties {
            label
          }
        }
      }
    }
  }
`

export default ProductPage
